import { ProprieteAffichee, CustomAffichage, TypePropriete } from "./proprieteAffichee";

export class Utilisateur {
    id: string;
    userName: string;
    email: string;
    nom: string;
    nomRole: string;
    lockoutEnd: Date;
    dateCreation: Date;
    dateDerniereModification: Date;
    dateDerniereConnexion: Date;
    estActif: boolean;
    motDePasse: string;

    getProprietesAffichees() : ProprieteAffichee[]{
        let tabProp: ProprieteAffichee[] = [];
        //tabProp.push(new ProprieteAffichee('estActif', 'Actif', '',
        //    new CustomAffichage(TypePropriete.Boolean, 'fas fa-check-circle checkbox-epc-green', 'fas fa-minus-circle checkbox-epc-red')));
        tabProp.push(new ProprieteAffichee('userName', 'Identifiant', ''));
        tabProp.push(new ProprieteAffichee('nom', 'Nom', ''));
        tabProp.push(new ProprieteAffichee('nomRole', 'Role', ''));
        tabProp.push(new ProprieteAffichee('dateDerniereConnexion', 'Dernière connexion', '', new CustomAffichage(TypePropriete.Date)));
        return tabProp;
    }
}
