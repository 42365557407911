import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Utilisateur } from '../../bo/utilisateur';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Component({
    selector: 'app-utilisateur-delete-modale',
    templateUrl: './utilisateur-delete-modale.component.html',
    styleUrls: ['./utilisateur-delete-modale.component.scss']
})
/** utilisateur-delete-modale component*/
export class UtilisateurDeleteModaleComponent {
  utilisateur: Utilisateur;
  deleteReussie: boolean;

    /** utilisateur-delete-modale ctor */
  constructor(private http: HttpClient, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<UtilisateurDeleteModaleComponent>) {
    if (this.data.utilisateur) {
      this.utilisateur = JSON.parse(JSON.stringify(this.data.utilisateur));
    }
  }

  supprimer() {
    this.http.delete('api/Utilisateurs?id=' + this.utilisateur.id, httpOptions).subscribe(() => {
      this.deleteReussie = true;
      this.dialogRef.close();
    });
  }
}
