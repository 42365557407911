import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './component/nav-menu/nav-menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { EstConnecte } from './guard/est-connecte.guard';
import { EstNonConnecte } from './guard/est-non-connecte.guard';
import { UtilisateurComponent } from './component/utilisateur/utilisateur.component';
import { ConnexionComponent } from './component/connexion/connexion.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule, MatDialogModule } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { NgxUiLoaderModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { ListeGeneriqueComponent } from './component/liste-generique/liste-generique.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FiltrePipe } from './pipe/filtre';
import { FiltreDatePipe } from './pipe/filtre-date';
// Utilisé par angular pour le datePipe
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);
// Utilisé par ngx-bootstrap (datepicker)
import { defineLocale } from 'ngx-bootstrap/chronos';
import { frLocale } from 'ngx-bootstrap/locale';
import { UtilisateurModaleComponent } from './component/utilisateur-modale/utilisateur-modale.component';
import { UtilisateurService } from './service/utilisateur.service';
import { UtilisateurDeleteModaleComponent } from './component/utilisateur-delete-modale/utilisateur-delete-modale.component';
defineLocale('fr', frLocale);



export function loadContext(utilService: UtilisateurService) {
    return () => utilService.initialisation();
}

@NgModule({
    declarations: [
        AppComponent,
        NavMenuComponent,
        ConnexionComponent,
        UtilisateurComponent,
        ListeGeneriqueComponent,
        FiltrePipe,
        FiltreDatePipe,
        UtilisateurModaleComponent,
        UtilisateurDeleteModaleComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        FormsModule,
        RouterModule.forRoot([
            { path: '', pathMatch: 'full', redirectTo: 'utilisateurs' },
            { path: 'connexion', component: ConnexionComponent, canActivate: [EstNonConnecte] },
            { path: 'utilisateurs', component: UtilisateurComponent, canActivate: [EstConnecte] }
        ]),
        BrowserAnimationsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatDialogModule,
        NgxUiLoaderModule,
        NgxUiLoaderHttpModule.forRoot({ delay: 300, showForeground: true }),
        BsDatepickerModule.forRoot(),
        MatSelectModule
    ],
    providers: [
        CookieService,
        { provide: APP_INITIALIZER, useFactory: loadContext, deps: [UtilisateurService], multi: true },
    ],
    bootstrap: [AppComponent],
    entryComponents: [
      UtilisateurModaleComponent,
      UtilisateurDeleteModaleComponent
    ]
})
export class AppModule { }
