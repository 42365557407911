import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
    name: 'filtre',
    pure: false
})
export class FiltrePipe implements PipeTransform {
    nomProp: string = '';
    sousProp: string = '';

    constructor() { }

    transform(items: any[], filter: Map<string, string>): any[] {
        if (items && items.length > 0) {
            if (filter.size == 0)
                return items;
            else {
                return items.filter(item => {
                    var estOk = true;
                    filter.forEach((value, key, map) => {
                        this.nomProp = key;
                        this.sousProp = '';
                        if (key.lastIndexOf('\\') != -1) {
                            var valeur = key.split('\\');
                            this.nomProp = valeur[0];
                            this.sousProp = valeur[1];
                        };

                        var valeurItem = this.getValue(item);
                        if (valeurItem !== null && !valeurItem.toString().toLowerCase().includes(value.toLowerCase())) {
                            estOk = false;
                        }
                    });
                    return estOk;
                });
            }
        }
    }

    getValue(item: any) {
        if (this.sousProp === '')
            return item[this.nomProp];
        else
            return item[this.nomProp][this.sousProp];
    }
}
