import { Component, Input, Output, ViewEncapsulation, ViewChild } from '@angular/core';
import { ProprieteAffichee, TypePropriete } from '../../bo/proprieteAffichee';
import { EventEmitter } from '@angular/core';
import { BsDaterangepickerDirective, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { isNullOrUndefined } from 'util';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-liste-generique',
    templateUrl: './liste-generique.component.html',
    styleUrls: ['./liste-generique.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ListeGeneriqueComponent {
    @Input() proprietesAffichees: ProprieteAffichee[];
    @Input() hauteurListe: number = 59;
    @Input() listeObjets: any[];
    @Input() avecFiltrage: boolean = true;
    @Input() filtreParDefaut: any;
    @Input() triParDefaut: any;
  @Output() clicObjetEvent = new EventEmitter<any>();
  @Output() deleteObjetEvent = new EventEmitter<any>();
    @Input() listeModifiee: Observable<void>;
    @ViewChild('dpr', { static: false }) datepicker: BsDaterangepickerDirective;
    private listeModifieeAbonnement: any
    typeBoolean: number = TypePropriete.Boolean;
    typeDate: number = TypePropriete.Date;
    typeDuration: number = TypePropriete.Duration;
    typeString: number = TypePropriete.String;
    typeNumber: number = TypePropriete.Number;
    order: string = '';
    reverse: boolean;
    timerFiltrage: any = null;
    filtres = new Map<string, string>();
    filtresDate = new Map<string, string[]>();
    triProp: string = '';
    triSousProp: string = '';
    langueCourante: string;
    estTriDate: boolean;

    constructor(localeService: BsLocaleService) {
        localeService.use('fr');
    }

    ngOnInit() {
        if (this.listeModifiee) {
            this.listeModifieeAbonnement = this.listeModifiee.subscribe(() => {
                if (this.order.indexOf('\\') == -1) {
                    if (this.estTriDate)
                        this.setOrderDate(this.order, '', true);
                    else
                        this.setOrder(this.order, '', true);
                } else {
                    if (this.estTriDate)
                        this.setOrderDate(this.order.split('\\')[0], this.order.split('\\')[1], true);
                    else
                        this.setOrder(this.order.split('\\')[0], this.order.split('\\')[1], true);
                }
            });
        }
    }

    ngOnDestroy() {
        if (this.listeModifieeAbonnement)
            this.listeModifieeAbonnement.unsubscribe();
    }

    /**
     * Filtrage par défaut de la liste
     * */
    ngAfterViewInit() {
        if (this.filtreParDefaut) {
            if (this.filtreParDefaut.type == 'boolean') {
                (<HTMLSelectElement>document.getElementById(this.filtreParDefaut.prop)).value = this.filtreParDefaut.val;
                this.proprietesAffichees[this.filtreParDefaut.prop] = this.filtreParDefaut.val;
                this.filtrer(this.filtreParDefaut.prop, this.filtreParDefaut.val, '');
            }
            else if (this.filtreParDefaut.type == 'date')
                this.filtrerDates(this.filtreParDefaut.prop, this.filtreParDefaut.val, '');
        }
    }

    ngOnChanges() {
        if (this.listeObjets && this.listeObjets.length > 0) {
            if (this.triParDefaut) {
                if (this.triParDefaut.type == 'date') {
                    this.setOrderDate(this.triParDefaut.prop, isNullOrUndefined(this.triParDefaut.sousProp) ? '' : this.triParDefaut.sousProp, true);
                } else {
                    this.setOrder(this.triParDefaut.prop, isNullOrUndefined(this.triParDefaut.sousProp) ? '' : this.triParDefaut.sousProp, true);
                }
            }
        }
    }

    setOrder(nomPropriete: string, sousProp: string, callFromNgChanges: boolean = false) {
        this.triProp = nomPropriete;
        this.triSousProp = sousProp;
        this.estTriDate = false;

        if (!callFromNgChanges) {
            if (this.order === this.triProp + '\\' + this.triSousProp)
                this.reverse = !this.reverse;
            else
                this.reverse = false;
        }

        let cpt = 0;
        var valeur = null;

        while ((valeur === null || valeur === undefined) && cpt < this.listeObjets.length) {
            valeur = this.getValue(this.listeObjets[cpt]);
            cpt++;
        }

        switch (typeof (valeur)) {
            case 'string': {
                this.triString();
                this.order = this.triProp + '\\' + this.triSousProp;
                break;
            }
            case 'boolean':
            case 'number':
            case 'bigint': {
                this.tri();
                this.order = this.triProp + '\\' + this.triSousProp;
                break;
            }
            case 'undefined':
            case 'object':
            case 'symbol':
            case 'function':
            default: {
                break;
            }
        }
    }

    setOrderDate(nomPropriete: string, sousProp: string, callFromNgChanges: boolean = false) {
        this.triProp = nomPropriete;
        this.triSousProp = sousProp;
        this.estTriDate = true;

        if (!callFromNgChanges) {
            if (this.order === this.triProp + '\\' + this.triSousProp)
                this.reverse = !this.reverse;
            else
                this.reverse = false;
        }

        this.triDate();
        this.order = this.triProp + '\\' + this.triSousProp;
    }

    filtrer(propriete: string, valeur: string, sousProp: string = '') {
        if (sousProp === '')
            this.filtres.set(propriete, valeur);
        else
            this.filtres.set(propriete + '\\' + sousProp, valeur);
    }

    filtrerDates(propriete: string, valeur: Date[], sousProp: string = '') {
        if (valeur && valeur.length == 2) {
            let dateDeb = new Date(valeur[0].getFullYear(), valeur[0].getMonth(), valeur[0].getDate(), 0, 0, 0);
            let dateFin = new Date(valeur[1].getFullYear(), valeur[1].getMonth(), valeur[1].getDate(), 23, 59, 59);
            if (sousProp === '')
                this.filtresDate.set(propriete, [dateDeb.toISOString(), dateFin.toISOString()]);
            else
                this.filtresDate.set(propriete + '\\' + sousProp, [dateDeb.toISOString(), dateFin.toISOString()]);
        } else {
            this.filtresDate.clear();
        }
    }

    clicObjet(objet: any) {
        this.clicObjetEvent.emit(objet);
    }

    deleteObjet(objet: any) {
      this.deleteObjetEvent.emit(objet);
    }

    nettoyerDates(dpr: BsDaterangepickerDirective) {
        dpr.bsValue = null;
    }

    // #region Méthodes de tri
    triDate() {
        if (!this.reverse) {
            this.listeObjets.sort((a: any, b: any) => {
                var valA = this.getValue(a);
                var valB = this.getValue(b);
                if (!valA)
                    return -1;
                if (!valB)
                    return 1;

                let dateA = new Date(valA);
                let dateB = new Date(valB);
                if (dateA.getTime() > dateB.getTime())
                    return 1;
                if (dateA.getTime() < dateB.getTime())
                    return -1;
                return 0;
            });
        } else {
            this.listeObjets.sort((a: any, b: any) => {
                var valA = this.getValue(a);
                var valB = this.getValue(b);
                if (!valA)
                    return 1;
                if (!valB)
                    return -1;

                let dateA = new Date(valA);
                let dateB = new Date(valB);
                if (dateA.getTime() > dateB.getTime())
                    return -1;
                if (dateA.getTime() < dateB.getTime())
                    return 1;
                return 0;
            });
        }
    }

    triString() {
        if (!this.reverse) {
            this.listeObjets.sort((a: any, b: any) => {
                var valA = this.getValue(a);
                var valB = this.getValue(b);
                if (!valA)
                    return -1;
                if (!valB)
                    return 1;
                if (valA.toLowerCase() < valB.toLowerCase())
                    return -1;
                if (valA.toLowerCase() > valB.toLowerCase())
                    return 1;
                return 0;
            });
        } else {
            this.listeObjets.sort((a: any, b: any) => {
                var valA = this.getValue(a);
                var valB = this.getValue(b);
                if (!valA)
                    return 1;
                if (!valB)
                    return -1;
                if (valA.toLowerCase() > valB.toLowerCase())
                    return -1;
                if (valA.toLowerCase() < valB.toLowerCase())
                    return 1;
                return 0;
            });
        }
    }

    tri() {
        if (!this.reverse) {
            this.listeObjets.sort((a: any, b: any) => {
                var valA = this.getValue(a);
                var valB = this.getValue(b);
                // pas de vérification en mode 'if(!valA)' car la valeur peut être un boolean
                if (valA === undefined || valA === null)
                    return -1;
                if (valB === undefined || valB === null)
                    return 1;
                if (valA < valB)
                    return -1;
                if (valA > valB)
                    return 1;
                return 0;
            });
        } else {
            this.listeObjets.sort((a: any, b: any) => {
                var valA = this.getValue(a);
                var valB = this.getValue(b);
                // pas de vérification en mode 'if(!valA)' car la valeur peut être un boolean
                if (valA === undefined || valA === null)
                    return 1;
                if (valB === undefined || valB === null)
                    return -1;
                if (valA > valB)
                    return -1;
                if (valA < valB)
                    return 1;
                return 0;
            });
        }
    }

    getValue(item: any) {
        if (this.triSousProp === '')
            return item[this.triProp];
        else if (item[this.triProp])
            return item[this.triProp][this.triSousProp];
        else
            return undefined;
    }
    // #endregion 
}
