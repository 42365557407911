import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Utilisateur } from "../bo/utilisateur";
import { isNullOrUndefined } from "util";

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root',
})
export class UtilisateurService {
    estConnecte: boolean;
    utilisateur: Utilisateur;

    constructor(private http: HttpClient) { }

    async initialisation(){
        this.utilisateur = null;
        this.estConnecte = false;

        let _utilisateur = await this.http.get<Utilisateur>('api/Authentification/UtilisateurCourant', httpOptions).toPromise();
        if (!isNullOrUndefined(_utilisateur) && !isNullOrUndefined(_utilisateur.id)) {
            this.estConnecte = true;
            this.utilisateur = _utilisateur;
        }
    }
}
