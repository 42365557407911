export class ProprieteAffichee {
    nom: string;
    libelleAffiche: string;
    //typePropriete: TypePropriete;
    sousPropriete: string;
    customAffichage: CustomAffichage;

    constructor(nom: string, libAffiche: string, sousProp: string = '', customAffichage: CustomAffichage = null) {
        this.nom = nom;
        this.libelleAffiche = libAffiche;
        this.sousPropriete = sousProp;

        if (customAffichage == null || undefined)
            this.customAffichage = new CustomAffichage(TypePropriete.String);
        else
            this.customAffichage = customAffichage;
    }
}

export class CustomAffichage {
    typePropriete: TypePropriete;
    boolClasseVrai: string;
    boolClasseFaux: string;
    dateFormat: string;

    constructor(typeProp: TypePropriete, boolVrai: string = '', boolFaux: string = '', formatDate: string = 'shortDate') {
        this.typePropriete = typeProp;
        this.boolClasseVrai = boolVrai;
        this.boolClasseFaux = boolFaux;
        this.dateFormat = formatDate;
    }
}

export enum TypePropriete {
    Boolean,
    Date,
    String,
    Duration,
    Number
}
