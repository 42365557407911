import { Component } from '@angular/core';
import { UtilisateurService } from './service/utilisateur.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
    
    constructor(public utilService:UtilisateurService) {

    }
}
