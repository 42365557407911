import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-nav-menu',
    templateUrl: './nav-menu.component.html',
    styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
    isExpanded = false;

    constructor(private http: HttpClient, private cookieService: CookieService) {}

    seDeconnecter() {
        this.http.post('api/Authentification/Deconnexion', '').subscribe(() => {
            window.location.reload(true);
        }, () => {
            this.cookieService.delete("epcCookie", "/");
            window.location.reload(true);
        });
    }
}
