import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UtilisateurService } from "../service/utilisateur.service";

@Injectable({
    providedIn: 'root'
})
export class EstConnecte implements CanActivate {
    constructor(private router: Router, private utilService: UtilisateurService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.utilService.estConnecte) {
            this.router.navigate(['connexion']);
            return false;
        }
        return true;
    }
}
