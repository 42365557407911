import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
    name: 'filtreDate',
    pure: false
})
export class FiltreDatePipe implements PipeTransform {
    nomProp: string;
    sousProp: string;
    constructor() { }

    transform(items: any[], filtres: Map<string, string[]>) {
        if (items && items.length > 0) {
            if (!filtres || filtres.size == 0)
                return items;
            else {
                let dateDebut: Date;
                let dateFin: Date;
                return items.filter(item => {
                    var estOk = true;
                    filtres.forEach((value, key, map) => {
                        this.nomProp = key;
                        this.sousProp = '';
                        if (key.lastIndexOf('\\') != -1) {
                            var valeur = key.split('\\');
                            this.nomProp = valeur[0];
                            this.sousProp = valeur[1];
                        };
                        dateDebut = new Date(value[0]);
                        dateFin = new Date(value[1]);
                        var dateItem = this.getValue(item);

                        if (dateItem)
                            estOk = dateItem >= dateDebut && dateItem <= dateFin;
                        else
                            estOk = false;
                    });
                    return estOk;
                });
            }
        }
    }

    getValue(item: any) {
        let valeur;
        if (this.sousProp === '')
            valeur = item[this.nomProp];
        else
            valeur = item[this.nomProp][this.sousProp];

        if (valeur)
            return new Date(valeur);
        return null;
    }
}
