import { Component, TemplateRef, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { UtilisateurService } from '../../service/utilisateur.service';
import { Router } from '@angular/router';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Component({
    selector: 'app-connexion',
    templateUrl: './connexion.component.html',
    styleUrls: ['./connexion.component.scss']
})

export class ConnexionComponent {
    @ViewChild('modaleErreur', { static: false }) modaleErreur: TemplateRef<any>;
    hide: boolean = true;
    identifiant = new FormControl('', [Validators.required]);
    motDePasse = new FormControl('', [Validators.required]);
    compteBloque: boolean = false;
    idInvalides: boolean = false;
    compteClient: boolean = false;

    constructor(private http: HttpClient, private dialog: MatDialog, private router: Router, private utilService: UtilisateurService) {}

    login() {
        if (!this.identifiant.errors && !this.motDePasse.errors) {
            let body = JSON.stringify({ Username: this.identifiant.value, Password: this.motDePasse.value });

            this.http.post('api/Authentification/Connexion', body, httpOptions).subscribe(resultat => {
                if (resultat["succeeded"]) {
                    this.utilService.estConnecte = true;
                    this.router.navigate(['utilisateurs']);
                } else {
                    this.compteBloque = resultat["isLockedOut"];
                    this.compteClient = resultat["isNotAllowed"];
                    this.idInvalides = !this.compteBloque && !this.compteClient;
                    this.dialog.open(this.modaleErreur);
                }
            }, () => {
                this.compteBloque = false;
                this.compteClient = false;
                this.idInvalides = false;
                this.dialog.open(this.modaleErreur);
            });
        }
    }
}
