import { Component, Inject, ViewChild, TemplateRef } from '@angular/core';
import { Validators, FormControl, ValidationErrors, FormGroup, ValidatorFn } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { Utilisateur } from '../../bo/utilisateur';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

export const matchMotsDePasse: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    const mdp = control.get('motDePasse');
    const confirmation = control.get('confirmation');

    !confirmation || mdp.value !== confirmation.value ? confirmation.setErrors({ 'notMatch': true }) : confirmation.setErrors(null);
    return null;
};

@Component({
    selector: 'app-utilisateur-modale',
    templateUrl: './utilisateur-modale.component.html',
    styleUrls: ['./utilisateur-modale.component.scss']
})

export class UtilisateurModaleComponent {
    @ViewChild('modaleConfirm', { static: false }) modaleConfirm: TemplateRef<any>;
    hide: boolean = true;
    hideConfirm: boolean = true;
    nomRole = '';
    nomRoleBackup = '';
    estModif: boolean = false;
    utilisateur: Utilisateur;
    monFormulaire: FormGroup;
    modaleConfirmation: MatDialogRef<any, any>;
    modifReussie: boolean;
    erreurApi: boolean = false;

    get nom() { return this.monFormulaire.get('nom'); }
    get identifiant() { return this.monFormulaire.get('identifiant'); }
    get motDePasse() { return this.monFormulaire.get('motsPasse.motDePasse'); }
    get confirmation() { return this.monFormulaire.get('motsPasse.confirmation'); }

    constructor(private http: HttpClient, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<UtilisateurModaleComponent>,
        private dialog: MatDialog) { }

    ngOnInit() {
        if (this.data.utilisateur) {
            this.utilisateur = JSON.parse(JSON.stringify(this.data.utilisateur));
            this.estModif = true;
            this.nomRole = this.utilisateur.nomRole;
            this.nomRoleBackup = this.utilisateur.nomRole;
        } else {
            this.utilisateur = new Utilisateur();
            this.nomRole = 'Client';
        }

        this.monFormulaire = new FormGroup({
            nom: new FormControl(this.utilisateur.nom, [Validators.required, Validators.minLength(5)]),
            identifiant: new FormControl(this.utilisateur.userName, [Validators.required, Validators.minLength(5), Validators.pattern('[A-Za-z0-9@_\.\S\-]*')]),
            motsPasse: new FormGroup({
                motDePasse: new FormControl(''),
                confirmation: new FormControl('')
            }, { validators: matchMotsDePasse })
        });
        if (!this.data.utilisateur) {
            (<FormGroup>this.monFormulaire.controls['motsPasse']).controls.motDePasse.setValidators([Validators.required, Validators.minLength(5)]);
        }
    }

    enregistrer() {
        if (this.monFormulaire.valid && (<FormGroup>this.monFormulaire.controls['motsPasse']).valid) {
            this.utilisateur.userName = this.estModif ? this.utilisateur.userName : this.monFormulaire.controls['identifiant'].value;
            this.utilisateur.nom = this.monFormulaire.controls['nom'].value;
            this.utilisateur.nomRole = this.nomRole != this.nomRoleBackup ? this.nomRole : '';
            this.utilisateur.motDePasse = (<FormGroup>this.monFormulaire.controls['motsPasse']).controls['motDePasse'].value;
            let body = JSON.stringify(this.utilisateur);
            if (this.estModif) {
                this.http.put('api/Utilisateurs', body, httpOptions).subscribe(() => {
                    this.modifReussie = true;
                    if ((<FormGroup>this.monFormulaire.controls['motsPasse']).controls['motDePasse'].value)
                        this.modaleConfirmation = this.dialog.open(this.modaleConfirm, { hasBackdrop: true, disableClose: true });
                    else
                        this.dialogRef.close();
                }, () => {
                    this.modifReussie = false;
                    this.erreurApi = true;
                });
            }
            else {
                this.http.post('api/Utilisateurs', body, httpOptions).subscribe(resultat => {
                    this.modifReussie = resultat['succeeded'];
                    if (resultat['succeeded']) {
                        if (!this.estModif || (this.estModif && this.monFormulaire.controls['motDePasse'].value))
                            this.modaleConfirmation = this.dialog.open(this.modaleConfirm, { hasBackdrop: true, disableClose: true });
                        else
                            this.dialogRef.close();
                    } else if (resultat['errors'][0]['code'] == 'DuplicateUserName') {
                        this.monFormulaire.controls['identifiant'].setErrors({ 'duplicateName': true });
                    }
                }, () => {
                    this.modifReussie = false;
                    this.erreurApi = true;
                });
            }
        }
    }

    partagerParMail() {
        var subject = this.estModif ? 'Modification de votre compte' : 'Création de votre compte';
        var emailBody = 'Pour rappel, voici les identifiants de connexion :\nIdentifiant : ' + this.identifiant.value + '\nMot de passe : ' + this.motDePasse.value;
        document.location.href = "mailto:?subject=" + subject + "&body=" + encodeURIComponent(emailBody);
    }

    fermerModales() {
        this.modaleConfirmation.close();
        this.dialogRef.close();
    }
}
