import { Component } from '@angular/core';
import { Utilisateur } from '../../bo/utilisateur';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProprieteAffichee } from '../../bo/proprieteAffichee';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { MatDialog } from '@angular/material';
import { UtilisateurModaleComponent } from '../utilisateur-modale/utilisateur-modale.component';
import { UtilisateurDeleteModaleComponent } from '../utilisateur-delete-modale/utilisateur-delete-modale.component';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Component({
    selector: 'app-utilisateur',
    templateUrl: './utilisateur.component.html',
    styleUrls: ['./utilisateur.component.scss']
})

export class UtilisateurComponent {
    tabUtilisateurs: Utilisateur[];
    propUtilisateur: ProprieteAffichee[];
    erreurApi: boolean = false;

    constructor(private http: HttpClient, localeService: BsLocaleService, private dialog: MatDialog) {
        localeService.use('fr');
    }

    ngOnInit() {
        this.propUtilisateur = new Utilisateur().getProprietesAffichees();
        this.rafraichirDonnees();
    }

    ouvrirModale(utilisateur: Utilisateur) {
        const modale = this.dialog.open(UtilisateurModaleComponent, { data: { utilisateur: utilisateur }, hasBackdrop: true, disableClose: true });
        modale.beforeClose().subscribe(() => {
            if (modale.componentInstance.modifReussie)
                this.rafraichirDonnees();
        });
    }

    rafraichirDonnees() {
        this.http.get<Utilisateur[]>('api/Utilisateurs', httpOptions).subscribe(resultat => {
            this.tabUtilisateurs = resultat;
        }, () => {
            this.erreurApi = true;
        });
  }


  deleteUser(utilisateur: Utilisateur) {
    const modale = this.dialog.open(UtilisateurDeleteModaleComponent, { data: { utilisateur: utilisateur }, hasBackdrop: true, disableClose: true });
    modale.beforeClose().subscribe(() => {
      if (modale.componentInstance.deleteReussie)
        this.rafraichirDonnees();
    })
  }
}
